(function () {
  'use strict';

  class EditSchools {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, school, callback) {
      const vm = this;
      vm.obj = school;
      vm.$mdDialog.show({
        controller: 'EditSchoolsCtrl',
        controllerAs: 'editSchools',
        templateUrl: 'components/edit-schools/edit-schools.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.obj;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:EditSchools
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('EditSchools', EditSchools);
}());
